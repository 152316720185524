<template>
  <div class="sort">
    <div class="content">
      <div class="sort-main flex_b">
        <div class="sort-l">
          <div
            :class="['item', sortActive == 1 ? 'on' : '']"
            @click="sortChange(1)"
          >
            综合排序
          </div>
          <div
            :class="['item', sortActive == 2 ? 'on' : '']"
            @click="sortChange(2)"
          >
            销量
            <span class="el-icon-bottom" v-if="!sortNumUp"></span>
            <span class="el-icon-top" v-if="sortNumUp"></span>
          </div>
          <div
            :class="['item', sortActive == 3 ? 'on' : '']"
            @click="sortChange(3)"
          >
            时间
            <span class="el-icon-bottom" v-if="!sortNewUp"></span>
            <span class="el-icon-top" v-if="sortNewUp"></span>
          </div>
          <div
            :class="['item', sortActive == 4 ? 'on' : '']"
            @click="sortChange(4)"
          >
            热度
            <span class="el-icon-bottom" v-if="!sortHotUp"></span>
            <span class="el-icon-top" v-if="sortHotUp"></span>
          </div>
          <div
            :class="['item', sortActive == 5 ? 'on' : '']"
            @click="sortChange(5)"
          >
            价格
            <span class="el-icon-bottom" v-if="!sortPriceUp"></span>
            <span class="el-icon-top" v-if="sortPriceUp"></span>
          </div>
          <div class="price-area">
            <el-input
              v-model="priceGte"
              @change="change"
              type="number"
              size="small"
              placeholder="请输入最低价"
            >
              <i slot="prefix" class="price-icon">￥</i>
            </el-input>
            <span>-</span>
            <el-input
              v-model="priceLte"
              @change="change"
              type="number"
              size="small"
              placeholder="请输入最高价"
            >
              <i slot="prefix" class="price-icon">￥</i>
            </el-input>
          </div>
        </div>
        <!-- <div class="sort-r">
          <el-checkbox v-model="yzTaskTags" @change="change"
            ><span class="urgent"
              ><i class="iconfont icon-thunderbolt-fill"></i> 急</span
            ></el-checkbox
          >
          <el-checkbox v-model="yzTaskTags2" @change="change">官方认证</el-checkbox>
          <div class="site-check">
            <span>收货地：</span>
            <el-cascader
              v-model="siteCheck"
              :options="provinceAndCityDataPlus"
              @change="siteChange"
              :props="{
                value: 'areaCode',
                label: 'areaName',
                children: 'childList',
                leaf: 'leaf',
              }"
            ></el-cascader>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hasPays: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      yzTaskTags: false,
      yzShopMainList: [],
      sortActive: 1,
      siteCheck: [],
      sortPriceUp: true,
      sortNewUp: false,
      sortHotUp: false,
      sortNumUp:false,
      provinceAndCityDataPlus: [],
      areaCode: "",
      yzTaskTags2: false,
      priceGte: "",
      priceLte: "",
    };
  },
  mounted() {
    //省市区数据
    // this.provinceAndCityDataPlus = regionDataPlus;
    //服务商地区
    this.$sapi.get("/api/v1/getAreaJson.json").then((res) => {
      this.provinceAndCityDataPlus = [
        { areaCode: "-1", areaName: "全部" },
        ...res.map((item) => {
          if (item.childList && !item.childList.length) {
            delete item.childList;
          } else {
            item.childList = [
              { areaCode: "-1", areaName: "全部" },
              ...item.childList.map((item2) => {
                if (item2.childList && !item2.childList.length) {
                  delete item2.childList;
                } else if (item2.childLis && item2.childList.lengtht) {
                  item2.childList = [
                    { areaCode: "-1", areaName: "全部" },
                    ...item2.childList.map((item3) => {
                      if (item3.childList && !item3.childList.length) {
                        delete item3.childList;
                      }
                      return item3;
                    }),
                  ];
                }
                return item2;
              }),
            ];
          }
          return item;
        }),
      ];
    });
  },
  watch: {
    hasPays(newval, oldval) {
      this.hasPay = newval;
    },
  },
  methods: {
    change() {
      //asc升序   desc降序
      let orderBy = "a.shop_sort";
      if (this.sortActive == 2) {
        orderBy = `a.sales_num ${this.sortNumUp ? "asc" : "desc"}`;
      } else if (this.sortActive == 3) {
        orderBy = `a.create_date ${this.sortNewUp ? "asc" : "desc"}`;
      } else if (this.sortActive == 4) {
        orderBy = `a.view_num ${this.sortHotUp ? "asc" : "desc"}`;
      } else if (this.sortActive == 4) {
        orderBy = `a.shop_price ${this.sortPriceUp ? "asc" : "desc"}`;
      }
      let yzTaskTags = "";
      if (this.yzTaskTags && !this.yzTaskTags2) {
        yzTaskTags = "3";
      } else if (!this.yzTaskTags && this.yzTaskTags2) {
        yzTaskTags = "6";
      } else if (this.yzTaskTags && this.yzTaskTags2) {
        yzTaskTags = "3,6";
      }
      this.$emit("change", {
        areaCode: this.areaCode,
        orderBy,
        yzTaskTags,
        priceGte: this.priceGte,
        priceLte: this.priceLte,
      });
    },
    upChange(item) {},

    sortChange(index) {
      if (index == 1) {
        this.sortNumUp = false;
        this.sortPriceUp = true;
        this.sortNewUp = false;
        this.sortHotUp = false;
      } else if (index == 2) {
        this.sortActive == index ? (this.sortNumUp = !this.sortNumUp) : null;
        this.sortPriceUp = true;
        this.sortNewUp = false;
        this.sortHotUp = false;
      } else if (index == 3) {
        this.sortActive == index ? (this.sortNewUp = !this.sortNewUp) : null;
        this.sortPriceUp = true;
        this.sortNumUp = false;
        this.sortHotUp = false;
      } else if (index == 5) {
        this.sortActive == index
          ? (this.sortPriceUp = !this.sortPriceUp)
          : null;
        this.sortNewUp = false;
        this.sortNumUp = false;
        this.sortHotUp = false;
      } else if (index == 4) {
        this.sortActive == index ? (this.sortHotUp = !this.sortHotUp) : null;
        this.sortNewUp = false;
        this.sortNumUp = false;
        this.sortPriceUp = true;
      }
      this.sortActive = index;
      this.change();
    },
    siteChange(arr) {
      let code = "";
      if (arr.length == 1 && arr[0] != "-1") {
        code = arr[0].substring(0, 3);
      } else if (arr.length == 2) {
        code = arr[1] != "-1" ? arr[1].substring(0, 3) : arr[0].substring(0, 3);
      } else if (arr.length == 3) {
        code = arr[2] != "-1" ? arr[2].substring(0, 3) : arr[1].substring(0, 3);
      }
      this.areaCode = code;
      this.change();
    },
  },
};
</script>